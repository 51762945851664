import axios from '@axios'

export default {
  namespaced: true,
  state: {
    clockingListToUpdate: false,
    isSidebarActive: false,
    clockingId: '',
    clockingData: {},
    clockingFiles: []
  },
  getters: {},
  mutations: {
    SET_IF_CLOCKING_LIST_UPDTAE(state, val){
      state.clockingListToUpdate = val
    },
    TOGGLE_SIDEBAR(state, val){ // Open/close
      state.isSidebarActive = val
    },
    RESET_CLOCKING(state, val){
      state.clockingId = '',
      state.clockingData = {},
      state.clockingFiles = []
    },
    SET_CLOCKING_ID(state, val){
      state.clockingId = val
    },
    SET_CLOCKING_DATA(state, data){
      state.clockingData = data
      state.clockingFiles = data.files
    }
  },
  actions: {
    fetchClockings({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/clocking', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('SET_IF_CLOCKING_LIST_UPDTAE', false)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchClocking({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/clocking/${id}`)
          .then(response => {
            commit('SET_CLOCKING_DATA', response.data.clocking)
            resolve(response.data)
          }).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateClocking(ctx, {id, clocking}) {
      return new Promise((resolve, reject) => {
        axios.post(`/clocking/${id||''}`, { ...clocking })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    disableClocking(ctx, id){
      return new Promise((resolve, reject) => {
        axios.delete(`/clocking/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(ctx, {id, fileName}){
      let url =''
      if (id) { url = `/clocking/${id}/file/${fileName.replaceAll('/','%2F')}` } else { url = `/clocking/file/${fileName}` }
      return new Promise((resolve, reject) => {
        axios.delete(url)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  },
}
