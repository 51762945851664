import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import clockingStoreModule from './clockingStoreModule'
if (!store.hasModule('clocking')) store.registerModule('clocking', clockingStoreModule)
import { Dialog, swal, Toast } from '@core/utils/other'

export default function useClocking(props, emit) {
  const loading = ref(false)

  // Table basic
  let tableColumns = [ //key: for table, field for DB
    { key: 'clockIn', label:'Date', sortable: true },
    { key: 'route', sortable: true, searchable: true },
    { key: 'point', label:"Location", sortable: true, searchable: true },
    { key: 'guard', sortable: true, searchable: true },
    { key: 'device', sortable: true, searchable: true },
    // { key: 'actions' },
  ]
  if (props && props.selectable === true) tableColumns.unshift({ key: 'selected', label:''})
  const perPage = ref(25)
  const count = ref(0)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100, 200]
  const searchQuery = ref('')
  const sortBy = ref('clockIn')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refClockingListTable.value ? refClockingListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: total.value,
    }
  })

  const refetchData = () => {
    refClockingListTable.value.refresh()
  }

  // Table filters
  const filterInputs = ref({})
  const filterSelects = ref({})
  // const filterTable = ref({disabled: {$ne: true}})
  // const filterTableOptions =ref([
  //   {title:'valid', value: {disabled: {$ne: true}}},
  //   {title:'all', value:null}
  // ])

  // ClockIn filters
  const clockInFilters = ref(null)

  // 延迟
  let awaitingStartTime = 0;
  const awaitAction = (func, duration=750) => {
    let selfStartTime = + new Date()
    awaitingStartTime = selfStartTime;
    setTimeout(()=>{
      if (awaitingStartTime === selfStartTime) func()
    }, duration)
  }

  // Table selection
  const selectedData = ref([])
  const onRowSelected = (val)=>{selectedData.value = val}
  const selectAllCheckbox =ref(null)
  const refClockingListTable = ref(null) // Table obj
  const selectAll = function (val){
    if (val) {
      refClockingListTable.value.selectAllRows()
    } else {
      refClockingListTable.value.clearSelected()
    }
  }

  watch([currentPage, perPage, filterSelects], () => { refetchData() }, {deep: true})
  watch([searchQuery, filterInputs], () => { awaitAction(refetchData) }, {deep: true})
  watch(selectedData, ()=>{
    if (!selectedData.value.length) selectAllCheckbox.value = false
    emit('update:selected-data', selectedData.value)
  })

  // 获取列表
  const clockings = ref([])
  const fetchClockings = (ctx, callback) => {
    selectAllCheckbox.value = false
    let storeObj = {
      fields: searchQuery.value?'tags':'',
      q: searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value,
      sort: isSortDirDesc.value? ('-' + sortBy.value) : sortBy.value,
    }

    // Head filters
    let filtersArry = []
    let filters = {...filterInputs.value, ...filterSelects.value}
    for (let key in filters){
      if (key && filters[key]) {
        filtersArry.push({ [key]: filters[key]})
      }
    }
    //if (filterTable.value) filtersArry.push(filterTable.value)
    if (clockInFilters.value) filtersArry.push(clockInFilters.value)
    storeObj.filters = JSON.stringify(filtersArry)
    
    store.dispatch('clocking/fetchClockings', storeObj)
    .then(response => {
      callback(response.clockings)
      clockings.value = response.clockings
      total.value = response.total
      count.value = response.count
    }).catch(error => {
      Toast.fire({icon:'error', title:error.message})
    })
  }

  // 获取CVS data
  const fetchAllClockings = async () => {
    let storeObj = {
      q: searchQuery.value,
      perPage: 5000,
      sort: isSortDirDesc.value? ('-' + sortBy.value) : sortBy.value,
    }

    // Head filters
    let filtersArry = []
    let filters = {...filterInputs.value, ...filterSelects.value}
    for (let key in filters){
      if (key && filters[key]) {
        filtersArry.push({ [key]: filters[key]})
      }
    }
    if (!clockInFilters.value) {
      await Dialog(false).fire({icon:'error', title:'Must to put in date range'});
      return  
    }
    filtersArry.push(clockInFilters.value)
    storeObj.filters = JSON.stringify(filtersArry)
    
    return await store.dispatch('clocking/fetchClockings', storeObj)
    .then(response => {
      return response.clockings
    }).catch(error => {
      Toast.fire({icon:'error', title:error.message})
    })
  }

/// Sidebar
  const isSidebarActive = ref(false);
  watch(()=>store.state.clocking.isSidebarActive, val=>{ 
    isSidebarActive.value = val;
    if(!val){// Sidebar 关闭后重置
      store.commit('clocking/RESET_CLOCKING')
    }
  })

  const clockingId = ref('');
  watch(()=>store.state.clocking.clockingId, (val)=> clockingId.value = val)

  const clockingData = ref({});
  watch(()=>store.state.clocking.clockingData, (val)=>clockingData.value = val, {deep: true})

  const clockingFiles = ref([]);
  watch(()=>store.state.clocking.clockingFiles, (val)=>clockingFiles.value = val, {deep: true})

 
  // 获取 Clocking 数据
  const fetchClocking = function(id) {
    if (!id) return
    store.dispatch('clocking/fetchClocking', id).then(({clocking})=>{
      clockingData.value = clocking
    }).catch(error => {
      Toast.fire({icon:'error', title:error.message})
      store.commit('clocking/TOGGLE_SIDEBAR', false)
    })
  }

  // 更新 Clocking 数据  
  const updateClocking = function(){
    loading.value = true
    store.dispatch('clocking/updateClocking', {
      id: clockingId.value,
      clocking: clockingData.value
    }).then(()=>{
      Toast.fire({ icon:'success', title:'Success' })
      store.commit('clocking/SET_IF_CLOCKING_LIST_UPDTAE', true) // Set to update list
      store.commit('clocking/TOGGLE_SIDEBAR', false) // Close sidebar
      loading.value=false
    }).catch(error=>{
      Toast.fire({icon:'error', title: error.message})
      loading.value=false
    })
  }

  // Clocking 失效
  const disableClocking = function(id){
    swal.fire({
      title: 'Confirm to disable?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, disable!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      heightAuto: false
    }).then(v => {
      if(!v.value) return
      store.dispatch('clocking/disableClocking', id).then(() => {
        store.commit('clocking/SET_IF_CLOCKING_LIST_UPDTAE', true)
        swal.fire({
          icon: 'success',
          title: 'Disabled!',
          customClass: { confirmButton: 'btn btn-success' },
          timerProgressBar: true,
          timer: 1000,
          heightAuto: false
        }).catch(swal.noop)
        store.dispatch("count/updateCounts")
      }).catch(e=>{
        swal.fire({
          icon: 'error',
          title: 'Failed to delete!',
          text: e.message,
          customClass: { confirmButton: 'btn btn-success' },
          heightAuto: false
        })
      })
    })
  }
  

  // File Upload
  const successUpload = function(e){
    if (!clockingId.value){ //只有 Add new 才上传
      const uploadeObj = JSON.parse(e.target.response).files;
      if (uploadeObj && uploadeObj.length>0) clockingData.value.files = clockingData.value.files.concat(uploadeObj) // 上传的图片信息
    }
    Toast.fire({icon:'success', title:'Success'})
  }

  const failUpload = function(e){
    console.log(e);
    Toast.fire({icon:'error', title: 'upload failed'})
  }

  const deleteUpload = function(e){
    store.dispatch('clocking/removeFile', {
      id: clockingData.value._id || null,
      fileName: e.newFileName
    }).then(() => {
      if(!clockingData.value._id){ //如果是Add clocking
        // 移除照片信息
        for (const [i, file] of clockingData.value.files.entries()) {
          if (file.name === e.newFileName) delete clockingData.value.files.splice(i,1)
        }
      }
      Toast.fire({icon:'success', title: 'File deleted'})
    }).catch(e=>{
      Toast.fire({icon:'error', title: JSON.parse(e).message})
    })
  }


  return {
    loading, Toast,
    fetchClockings,
    disableClocking,

    // Table
    tableColumns,
    perPage,
    currentPage,
    total, count,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refClockingListTable,
    refetchData,

    onRowSelected,
    selectedData,
    selectAllCheckbox,
    selectAll,

    // Sidebar
    isSidebarActive,
    clockingId, clockingData, clockingFiles,
    fetchClocking, clockings,
    updateClocking,

    // Filters
    filterInputs,
    filterSelects,
    //filterTable, filterTableOptions,
    clockInFilters,

    // File upload functions
    successUpload,
    failUpload,
    deleteUpload,

    // Ecport CSV
    fetchAllClockings
  }
}
