<template>
  <div id="clocking-list" class="rounded bg-white shadow">
    <ClockingSidebar :update="update"/>
    <div class="p-2">
      <b-row>
        <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start gap-2 mb-1 mb-md-0">
          <template v-if="showIcon">
            <fa-icon :icon="['fad', 'clock']" size="2x" class="text-primary" />
            <span class="h4 m-0 text-primary">Clockings</span>
          </template>
          <div>
            <label class="text-capitalize">{{$t("table.showing")}}</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>{{$t("table.items")}}</label>
          </div>
        </b-col>
        <!-- Date Filter -->
        <b-col cols="12" md="6" class="d-flex gap-2 justify-content-end">
          <BInputGroup style="max-width:350px">
            <FlatPickr v-model="dateRange" class="h-100 form-control border-primary" :config="{ mode: 'range', maxDate:'today', onClose:updateFilterDate}" 
              placeholder="Date range"  />
            <template #prepend>
              <BButton variant="outline-primary"><fa-icon :icon="['fad','calendar-alt']" /></BButton>
            </template>
            <div class="input-group-append" @click.stop="clearFilterDate">
              <b-button variant="outline-primary"><fa-icon icon="times" /></b-button>
            </div>
          </BInputGroup>
          <JsonCSV ref="refCVS" id="123" :data="formattedClockings" class="d-none" :name="'clocking '+(new Date()).toLocaleString('ja-JP')+'.csv'"></JsonCSV>
          <q-btn :disabled="!dateRange || dateRange.length===0" push no-caps @click="downloadCVS" color="primary" icon="file_download"/>
       
        </b-col>
        <!-- Search -->
        <!-- <b-col cols="12" md="4" class="d-flex gap-2">
          <b-form-input v-model="searchQuery" class="d-inline-block" placeholder="Search..." />
          <JsonCSV ref="refCVS" id="123" :data="formattedClockings" class="d-none" :name="'clocking '+(new Date()).toLocaleString('ja-JP')+'.csv'"></JsonCSV>
          <q-btn :disabled="!dateRange || dateRange.length===0" push no-caps @click="downloadCVS" color="primary" icon="file_download"/>
        </b-col> -->
      </b-row>
    </div>

    <b-table ref="refClockingListTable" class="slim position-relative text-center" :items="fetchClockings" responsive :fields="tableColumns"
      primary-key="id" :sort-by.sync="sortBy" show-empty :sort-desc.sync="isSortDirDesc"
      :selectable="selectable?true:false" select-mode="multi" @row-selected="onRowSelected" >

      <template #empty>
        <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
        <h5>{{$t("table.noResult")}}</h5>
      </template>

      <!-- Header search -->
      <template #top-row="{ fields }">
        <td v-for="field in fields" :key="field.key" class="pl-0 pr-1">
          <div v-if="field.key=='selected'" class="text-center"><b-form-checkbox class="ml-1 d-inline-block" v-model="selectAllCheckbox"  @change="selectAll"/></div>
          <v-select v-else-if="field.key=='status'" label="title" v-model="filterSelects[field.key]" :options="$global.clocking_status" :reduce="a=>a.value" class="select-size-sm">
            <template v-slot:option="eachStatus">
              <fa-icon :icon="eachStatus.icon" fixed-width class="mr-1" :class="`text-${eachStatus.color}`"></fa-icon>{{eachStatus.title}}
            </template>
          </v-select>
          <b-form-input v-else-if="field.searchable" v-model="filterInputs[field.key]" size="sm" class="d-inline-block" :placeholder="field.label"/>
        </td>
      </template>

      <!-- Column: Select -->
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>

      <!-- Column: Clock In -->
      <template #cell(clockIn)="data">
        <div class="d-flex justify-content-between align-items-center" @click.stop="openSidebar('view', data.item)">
          <Clock :time="{isStatic:true, timestamp: data.item.clockIn}" width="60" 
            :needle="{second:{length:0}, hour:{lineWidth:10, color:isNight(data.item.clockIn)?'#fff':''}, minute: {lineWidth:6, color:isNight(data.item.clockIn)?'#fff':''}}" 
            :border="{color:'#7367f0'}" :background="{color: isNight(data.item.clockIn)?'#7367f0':''}" :number="{color: isNight(data.item.clockIn)?'#fff':''}"/>
          <div><h5>{{data.item.clockIn | date}}</h5> {{data.item.clockIn | time}}</div>
          <div>
            <fa-icon v-if="data.item.disabled" :icon="['fad','ban']" class="text-danger mr-1" />
            <fa-icon :icon="['far','chevron-right']" class="text-primary mx-50"/>
          </div>
        </div>
      </template>

      <!-- Column: Route -->
      <template #cell(route)="data">
        <div v-if="data.item.pointData" class="text-left">
          <q-chip color="light-primary" dense>{{data.item.pointData.name}}</q-chip>
        </div>
        <div v-else class="text-left">{{data.item.point}}</div>
      </template>

      <!-- Column: Location -->
      <template #cell(point)="data">
        <div v-if="data.item.pointData" class="text-left">
          <q-chip v-if="data.item.pointData.site" dense color="light-primary">{{data.item.pointData.site}}</q-chip>
          <div v-if="data.item.pointData.address">{{data.item.pointData.address}}</div>
          <q-chip v-if="data.item.pointData.zip" dense color="light-primary">{{data.item.pointData.zip}}</q-chip>
        </div>
        <div v-else class="text-left"><small>{{data.item.point}}</small></div>
      </template>

      <!-- Column: Guard -->
      <template #cell(guard)="data">
        <b-media v-if="data.item.guardData" vertical-align="center" class="text-left">
          <template #aside>
            <b-avatar size="32" :src="fileUrl(data.item.guardData.avatar, 'guard', true)" :variant="`light-primary`"><fa-icon v-if="!fileUrl(data.item.guardData.avatar, 'guard', true)" icon="user" /></b-avatar>
          </template>
          <div class="d-flex align-items-center">
            <div>
              <div class="font-weight-bold d-block text-nowrap h5 m-0">{{ data.item.guardData.name }}</div>
              <small class="text-primary">{{ data.item.guardData.rank }}</small>
            </div>
          </div>
        </b-media>
      </template>

      <!-- Column: Device -->
      <template #cell(device)="data">
        <template v-if="data.item.deviceData">
          <div class="text-left">{{data.item.deviceData.name}}</div>
          <div class="d-flex gap-2" >
            <div v-if="data.item.voltage"><!-- 电量 -->
              <fa-icon v-if="data.item.voltage>4.3" icon="battery-full" class="text-success"/>
              <fa-icon v-else-if="data.item.voltage>4.1" icon="battery-three-quarters" class="text-info"/>
              <fa-icon v-else-if="data.item.voltage>3.9" icon="battery-half" class="text-primary"/>
              <fa-icon v-else-if="data.item.voltage>3.7" icon="battery-quarter" class="text-warning"/>
              <fa-icon v-else icon="battery-quarter" class="text-danger"/>
            </div>
            <fa-icon v-if="data.item.iccid" icon="signal" class="text-primary" />
          </div>
        </template>
      </template>

      <!-- Column: Name -->
      <template #cell(name)="data">
        <b-media vertical-align="center" class="text-left" @click.stop="openSidebar('view', data.item)">
          <template #aside>
            <b-avatar size="32" :src="fileUrl(data.item.avatar, 'clocking', true)" :variant="`light-primary`"><fa-icon v-if="!fileUrl(data.item.avatar, 'clocking', true)" icon="user" /></b-avatar>
          </template>
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <div class="font-weight-bold d-block text-nowrap h5 m-0">{{ data.item.name }}</div>
              <small class="text-muted">{{ data.item.phone }}</small>
            </div>
            <div>
              <fa-icon v-if="data.item.disabled" :icon="['fad','ban']" class="text-danger mr-1" />
              <fa-icon :icon="['far','chevron-right']" class="text-primary mx-50"/>
            </div>
            
          </div>
        </b-media>
      </template>

      <!-- Column: Clocking number -->
      <template #cell(clockingNo)="data">
        <h5 class="mb-0 flex-grow-1 text-center">{{ data.item.clockingNo}} </h5>
      </template>

      <!-- Column: Tags -->
      <template #cell(tags)="data">
        <div class="d-flex flex-wrap gap-1">
          <BBadge v-for="(tag, index) in data.item.tags" variant="light-primary" :key="tag.index">{{tag}}</BBadge>
        </div>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap d-flex gap-3 align-items-center justify-content-center">
          <feather-icon @click.stop="openSidebar('view', data.item)" icon="EyeIcon" class="cursor-pointer" size="16"/>
          <!-- <feather-icon @click.stop="openSidebar('update', data.item)" icon="EditIcon" class="text-primary cursor-pointer" size="16"/> -->
          <!-- <feather-icon v-if="!data.item.disabled" @click.stop="disableClocking(data.item._id)" icon="XIcon" class="text-danger cursor-pointer" size="16"/> -->
        </div>
      </template>
    </b-table>

    <div class="p-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} items</span>
        </b-col>

        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="total" :per-page="perPage"
            first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text><feather-icon icon="ChevronLeftIcon" size="18" /></template>
            <template #next-text><feather-icon icon="ChevronRightIcon" size="18"/></template>
          </b-pagination>
        </b-col>

      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormInput, BTable, BMedia, BAvatar, 
  BBadge, BPagination, BFormCheckbox,
  BInputGroup, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import FlatPickr from 'vue-flatpickr-component'

import { ref, watch } from '@vue/composition-api'
import { fileUrl, date, time, isNight } from '@core/utils/filter'
import store from '@/store'
import useClocking from './useClocking'
import ClockingSidebar from './ClockingSidebar'
import { Dialog } from '@core/utils/other'

import JsonCSV  from "vue-json-csv";
//import  {vueClock} from 'vue-clock-lonlyape'
import Clock from '@core/components/clock/Clock'

export default {
  name: 'clocking-list',
  components: {
    BRow,
    BCol,
    BFormInput,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BPagination,
    BFormCheckbox,
    BInputGroup, BButton,
    
    vSelect,
    //VueSelect,
    FlatPickr,

    ClockingSidebar,
    JsonCSV,
    Clock
  },
  props:{
    showIcon:{
      type: Boolean,
      default: false
    },
    selectable:{
      type: Boolean,
      default: false
    }
  },
  filters:{
    date, time
  },
  setup() {

    const {
      // Sidebar
      clockingId,
      // Actions
      fetchClockings,
      disableClocking,
      fetchClocking,
      refetchData,
      // Table items
      refClockingListTable,
      tableColumns,
      perPage,
      currentPage,
      total, count,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      // Table selection
      onRowSelected,
      selectedData,
      selectAllCheckbox,
      selectAll,
      // Filter
      filterInputs,
      filterSelects,
      //filterTable, filterTableOptions,
      clockInFilters,
      // Export CSV
      fetchAllClockings
    } = useClocking()

    // Date filter
    const dateRange = ref(null)
    const updateFilterDate = function (selectedDates){
      let from = selectedDates[0]
      let to = selectedDates[1]
      if(!from || !to){
        dateRange.value=null
        clockInFilters.value=null
        return 
      }
      // 加一天
      to = new Date(to.getTime() + 24*3600*1000)
      if (to - from > 2678400000) return Dialog(false).fire({title:'Date range cannot be over 31 days'})
      clockInFilters.value = {clockIn: { $gte: from, $lt: to}}
      refetchData()
    }
    const clearFilterDate = function(){
      dateRange.value=null
      clockInFilters.value=null
      refetchData()
    }

    // 导出 CSV
    const refCVS = ref(null)
    const formattedClockings = ref([])
    const downloadCVS = function(){
      fetchAllClockings().then(response =>{
        const clockings = response
        if (!clockings || clockings.length === 0){
          formattedClockings.value=[]
        } else {
          formattedClockings.value = clockings.map(clocking => {
            return {
              'Clocked at': date(clocking.clockIn, true),
              'Device ID': clocking.device,
              'Device Name': clocking.deviceData?clocking.deviceData.name:'',
              'Guard ID': clocking.guard,
              'Guard Name': clocking.guardData?clocking.guardData.name:'',
              'Guard Rank': clocking.guardData?clocking.guardData.rank:'',
              'Point ID': clocking.point,
              'Point Name': clocking.pointData?clocking.pointData.name:'',
              'Site': clocking.pointData?clocking.pointData.site:'',
              'Address': clocking.pointData?clocking.pointData.address:'',
              'unit': clocking.pointData?clocking.pointData.unit:'',
              'Post code': clocking.pointData?clocking.pointData.zip:'',
            }
          })
          setTimeout(()=>refCVS.value.$el.click(), 200)
        }
      })
      
    }

    // sidebar
    const update = ref(null) // true for [update, add]
    watch(clockingId, val=>{ //放在这里避免多次请求
      if(val) fetchClocking(val)
    })

    watch(()=>store.state.clocking.clockingListToUpdate, val=>{
      if(val)refClockingListTable.value.refresh()
    })

    const openSidebar = function(type, clocking){
      if (type==='add'){
        update.value = true
        store.commit('clocking/SET_CLOCKING_ID', '')
        store.commit('clocking/TOGGLE_SIDEBAR', true)
      } else if (type==='update') {
        if (!clocking ||!clocking._id) return 
        update.value = true
        store.commit('clocking/SET_CLOCKING_ID', clocking._id)
        store.commit('clocking/TOGGLE_SIDEBAR', true)
      } else if(type==='view'){
        if (!clocking ||!clocking._id) return 
        update.value = false
        store.commit('clocking/SET_CLOCKING_ID', clocking._id)
        store.commit('clocking/TOGGLE_SIDEBAR', true)
      }
    }

    return {
      // Actions
      disableClocking,
      fetchClockings, formattedClockings,
      refetchData,
      
      // Sidebar
      update,
      openSidebar,

      refClockingListTable,
      tableColumns,
      perPage,
      currentPage,
      total, count,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,  
      // Table filters
      filterInputs,
      filterSelects,
      //filterTable, filterTableOptions,
      // Table selection
      onRowSelected,
      selectedData,
      selectAllCheckbox,
      selectAll,

      // Filter
      fileUrl, isNight,

      // Date filter
      dateRange, updateFilterDate, clearFilterDate, downloadCVS,
      clockInFilters,

      refCVS
    }
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.filter-table-selector{
  min-width: 90px
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>