<template>
  <div id="clocking-info" class="p-2">
    <div id="avatar" class="d-flex justify-content-center mb-3">
      <q-card class="w-100">
        <div v-if="!clockingData.pointData||!clockingData.pointData.avatar" class="text-center bg-light-primary"><fa-icon icon="route" size="5x" class="my-2"/></div>
        <q-img v-else :src="fileUrl(clockingData.pointData?clockingData.pointData.avatar:'', 'point', true)"/>
        <q-card-section>
          <b-avatar :src="fileUrl(clockingData.guardData?clockingData.guardData.avatar:'', 'guard', true)" size="100px" class="position-absolute " style="top: 0; right: 12px; transform: translateY(-50%);"/>
        </q-card-section>
        <q-card-section>
          <template v-if="clockingData.pointData">
            <h4 class="text-primary">{{clockingData.pointData.name}}</h4>
            <div><fa-icon :icon="['far','building']" class="text-primary mr-1 align-middle" /><q-chip dense class="bg-light-primary">{{clockingData.pointData.site}}</q-chip></div>
          </template>
          <div class="text-primar mt-50"><fa-icon :icon="['far','clock']" class="text-primary mr-1 align-middle" />{{clockingData.clockIn | date(true)}}</div>
        </q-card-section>
      </q-card>
    </div>
    <div class="d-flex flex-column gap-3">
      <!-- Site -->
      <div v-if="clockingData.site" class="d-flex gap-2">
        <fa-icon :icon="['fad','building']" fixed-width class="text-primary text-size-150"/>
        <div>
          <label class="text-primary d-block">{{$t('security.site')}}</label>
          {{clockingData.site}}
        </div>
      </div>
      <!-- Address/unit/zip -->
      <template v-if="clockingData.pointData">
        <div v-if="clockingData.pointData.address||clockingData.pointData.zip||clockingData.pointData.unit" class="d-flex gap-2">
          <fa-icon :icon="['fad','map-marker-alt']" fixed-width class="text-primary text-size-150"/>
          <div>
            <label class="text-primary d-block">{{$t('user.address')}}</label>
            <h5 v-if="clockingData.pointData.address" class="mb-25">{{clockingData.pointData.address}}</h5>
            <div v-if="clockingData.pointData.zip||clockingData.pointData.unit">
              <q-chip dense text-color="white" color="primary">{{clockingData.pointData.zip}}</q-chip> {{clockingData.unit}}
            </div>
          </div>
        </div>
      </template>

      <!-- Guard -->
      <div v-if="clockingData.guardData" class="d-flex gap-2">
        <fa-icon :icon="['fad','user-shield']" fixed-width class="text-primary text-size-150"/>
        <div>
          <label class="text-primary d-block">{{$t('user.name')}}</label>
          <h5 v-if="clockingData.guardData.name" class="mb-25">{{clockingData.guardData.name}}</h5>
          <div v-if="clockingData.guardData.rank" class="text-primary">{{clockingData.guardData.rank}}</div>
          <div v-if="clockingData.guardData.phone" class="text-primary"><fa-icon icon="phone-alt" class="mr-50" />{{clockingData.guardData.phone}}</div>
          <div v-if="clockingData.guardData.email" class="text-primary"><fa-icon icon="envelope" class="mr-50" />{{clockingData.guardData.email}}</div>
        </div>
      </div>

      <!-- Device -->
      <div v-if="clockingData.deviceData" class="d-flex gap-2">
        <fa-icon :icon="['fad','tablet-rugged']" fixed-width class="text-primary text-size-150"/>
        <div>
          <label class="text-primary d-block">{{$t('security.device')}}</label>
          <h5 v-if="clockingData.deviceData.name" class="mb-25">{{clockingData.deviceData.name}}</h5>
        </div>
      </div>
    </div>        
    
  </div>
</template>

<script>
import useClocking from '../useClocking'
import { BAvatar } from 'bootstrap-vue'

import FileUpload from '@core/components/file-upload/FileUpload'
import FileGallery from '@core/components/file-gallery/FileGallery'
import 'vuesax/dist/vuesax.css'
import { fileUrl, date } from '@core/utils/filter'

export default {
  name: 'clocking-info',
  props: {
    update:{
      type: Boolean,
      default: false
    },
  },
  filters: {
    date
  },
  components:{
    BAvatar,
    FileUpload, FileGallery
  },
  setup(props, {emit}){
    const { 
      loading,

      // Sidebar
      clockingId,
      clockingData,
      clockingFiles,
      updateClocking,

      // File
      successUpload,
      failUpload,
      deleteUpload
    } = useClocking(props, emit)

    return {
      loading,
      fileUrl,

      // Sidebar
      clockingId,
      clockingData,
      clockingFiles,
      updateClocking,

      // File
      successUpload,
      failUpload,
      deleteUpload
    }
  }
}
</script>

<style lang="scss">
.q-select {
  div.q-field__native{
    column-gap: 0.5rem;
  }
}
</style>
